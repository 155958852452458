export default {
   params: {
      transType0: "Mercadorias em estoque",
      transType1: "Contratual",
      directionType0: "Adesão",
      directionType1: "Alcance (um preço em um leilão)",
      lockStateType0: "Em execução",
      lockStateType1: "Fechado",
      lockStateType2: "Cancelado",
   },
   common: {
      withdraw1: "Conta de levantamento",
      withdraw2: "Seleccionar o Tipo",
      remark: "Observações",
      verify:"Credenciamento",
      verifySuccess:"Credenciamento aprovado",
      onlineService: "Atendimento ao cliente",
      tip1: "Digite o valor correto",
      tip2: "Saldo insuficiente da conta",
      confirm: "Definir",
      cancel: "Cancelamentos",
      tip: "Chamar a atenção para algo",
      register: "Registro",
      viewMore: "Veja mais",
      trade: "Negociações",
      empty: "Nenhum conteúdo no momento",
      searchPairs: "Pesquisar pares de moedas",
      and: "Responder cantando",
      next: "A próxima etapa",
      success: "Sucessos",
      more: "Mais",
      main: "Imagem principal",
      sub: "Imagem",
      copySuccess: "Sucesso na cópia！",
      copyError: "Falha na reprodução！",
      view: "Confira",
      all: "Completo",
      goSet: "Vá em frente e defina",
      available: "Usabilidade",
      confirmCancel: "Tem certeza de que deseja cancelar?？",
      copy: "Fazer uma cópia de",
      submit: "Apresentar (um relatório etc.)",
      send: "Envio",
      keep: "Continue fazendo o pedido.",
      revenue: "Retorno esperado",
   },
   mine: {
      lastTime: "Último login",
   },
   head: {
      mineInvite: "Convidar amigos",
      mineAddress: "Endereço de retirada",
      mine: "Centro de contas",
      orderCoin: "Pedido à vista",
      orderContract: "Ordem de contrato",
      orderSecond: "Ordens de opções",
      order: "Pedido (de compra)",
      assetsWithdraw: "Retirar dinheiro",
      assetsRecharge: "Carregamento de moedas",
      assetsTotal: "Depreciação total dos ativos",
      assets: "Responsabilidades",
      logout: "Aparecer (em um jornal etc.)",
      menuTit1: "A situação atual do mercado",
      menuTit2: "Negociações",
      menuTit3: "Gerenciamento financeiro",
      menuTit4: "Novas moedas em linha",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "Blog (palavra emprestada)",
      menuTit8: "Discover",
      menuItem0: "Negociação de opções",
      menuItem1: "Comércio de moedas",
      menuItem2: "Negociação de contratos",
      menuItem3: "Launchpad",
      menuItem4: "Votar na moeda",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "Entrar",
      register: "Registro",
      download: "Digitalize o código para fazer o download",
      noPermissions: "Seu endereço IP mostra os países onde os serviços da TokoCrypto são restritos.",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on TokoCrypto",
      tradeTab1: "Comércio de moedas",
      tradeTab2: "Negociação de contratos",
      tradeTag1: "Aumento e queda de ações em um dia de negociação",
      tradeTag2: "Moedas quentes",
      tradeTag3: "Prime",
      serviceTit: "Uma ampla gama de serviços para escolher",
      serviceTit1: "Transação à vista",
      servicedesc1: "Comprar, vender ou negociar criptomoedas pelo preço de mercado vigente.",
      serviceTit2: "Contrato perpétuo",
      servicedesc2: "Negociação de contratos por meio de posições compradas ou vendidas",
      serviceTit3: "Gerenciamento financeiro",
      servicedesc3: "Compromisso de um clique para aumentar a renda diária",
      serviceTit4: "Prime",
      servicedesc4: "Inscreva-se para receber novos tokens de qualidade",
      liveTit: "Transmissão ao vivo da TokoCrypto",
      whyTit: "Transmissão ao vivo da TokoCrypto",
      whyTit1: "Seguro e confiável",
      whySubtit1: "Compensação interna, auditoria e gerenciamento de riscos em tempo real As cold wallets são separadas das carteiras com várias assinaturas para proteger os fundos Auditoria profissional de organizações de segurança terceirizadas, Auditoria profissional de organizações de segurança terceirizadas",
      whyLink: "Exibir todos os mecanismos de segurança",
      whyTit2: "Mecanismo avançado de agregação de negócios",
      whySubtit2: "Mecanismo de agregação de latência ultrabaixa, taxa de transferência maciça, sem jitter em picos extremos, sem tempo de inatividade em picos",
      whyTit3: "Plataformas confiáveis",
      whySubtit3: "Como uma das 50 principais plataformas de negociação do mundo, a TokoCrypto segue rigorosamente as regras do setor e não participa de operações de mercado.",
      whyTit4: "Sistema de negociação rápido",
      whySubtit4: "A TokoCrypto oferece uma interface de negociação simples e clara e um processo de operação conveniente, permitindo que você negocie ativos digitais de forma mais conveniente.",
      guideTit: "Guia para iniciantes",
      guideh1: "Criar uma conta TokoCrypto",
      guidep1: "Registre-se agora para iniciar sua jornada de negociação!",
      guideBtn1: "Registro",
      guideh2: "Economizar dinheiro em um banco",
      guidep2: "Recarga rápida e crédito fácil!",
      guideBtn2: "Carregamento de moedas",
      guideh3: "Início da negociação",
      guidep3: "Comece a negociar Comece a negociar e aproveite as infinitas possibilidades!",
      guideBtn3: "Comércio de moedas",
      readyTop1: "Pronto para começar?？",
      readyMid: "A TokoCrypto possui tecnologia avançada para proporcionar a você uma experiência rápida de compra, venda e negociação. Negocie com segurança com nossa plataforma simples criada especialmente para você.",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "Experiência de financiamento",
      faqCont1: "O Crédito de Futuros é um bônus de empréstimo de dinheiro digital para os usuários da TokoCrypto para ajudá-los a entender a negociação de contratos perpétuos da TokoCrypto. Em comparação com a negociação tradicional de futuros da TokoCrypto, combinada com o valor principal dos ativos de criptografia fornecidos pelo usuário, o Crédito de Futuros pode ser usado como uma margem de abertura relativamente grande para a negociação de Crédito de Futuros.",
      faqTit2: "O que é a CoinBox?",
      faqCont2: "O Futures CoinBox é um novo recurso de produto da TokoCrypto que permite enviar uma CoinBox de criptomoeda a um amigo. Você pode criar uma CoinBox de criptomoeda e compartilhá-la com seus amigos. Quando seu amigo digitar a senha da CoinBox por meio do aplicativo TokoCrypto para receber o presente, ele receberá uma quantidade aleatória de criptomoeda da CoinBox. O crédito pode ser usado como uma margem relativamente grande para abrir posições em negociações de crédito de futuros.",
      faqTit3: "Guia do iniciante para ganhar dinheiro",
      faqCont3: "O mais recente produto de gerenciamento de riqueza de criptomoedas da TokoCrypto é criado pela equipe quantitativa da TokoCrypto, que consiste em gerentes de fundos de investimento quantitativos experientes, elites financeiras do setor de futuros e títulos e tecnólogos da BAT. A equipe foi estabelecida em Cingapura em 2021 e se concentra no gerenciamento quantitativo de ativos digitais com soluções profissionais de modelagem quantitativa e recursos de gerenciamento.",
      faqTit4: "Junte-se a nós ao vivo na TokoCrypto",
      faqCont4: "O Futures Live é um produto de transmissão ao vivo que apresenta os principais projetos de blockchain de memória e especialistas do setor que fazem as palestras e apresentações mais recentes e mais quentes. O crédito pode ser usado como uma margem de abertura relativamente grande na negociação de créditos de futuros.",
      faqTit5: "Como comprar criptomoedas na TokoCrypto",
      faqCont5: "A Futures TokoCrypto oferece vários provedores de serviços de compra de criptomoedas de terceiros para usuários em todo o mundo. Veja nosso artigo sobre serviços de compra de criptomoedas. O crédito pode ser usado como uma margem de abertura relativamente grande na negociação de créditos de futuros.",
      partnerTit: "Parceiro cooperativo",
   },
   assets: {
      tit: "Total de ativos",
      recharge: "Carregamento de moedas",
      withdraw: "Retirar dinheiro",
      transfer: "Transferência",
      tab1: "Total de ativos",
      tab2: "Conta de moedas",
      tab3: "Conta de contrato",
      tab4: "Conta quantitativa contratual (CQA)",
      tab5: "Conta financeira",
      assetsTotal: "Total (geral)",
      assetsReal: "Usabilidade",
      assetsFrozen: "Congelamento (empréstimo, salário, preço etc.)",
      proportion: "Porcentagem de ativos",
      tip1: "Valor mínimo de saque",
      tip2: "Os saques abaixo do valor mínimo não serão creditados e não poderão ser reembolsados.",
      tip3: "Valor mínimo de saque",
      linkType: "Tipo de corrente",
      rechargeAddress: "Endereço de recarga",
      contractAddress: "Endereço do contrato",
      rechargeRecord: "Registro de enchimento de moedas",
      tip4: "Se você não tiver concluído a configuração da senha, não será possível recarregar as moedas por enquanto.",
      withrawAddress: "Endereço de retirada",
      placeWithrawAddress: "Digite o endereço de retirada",
      withrawAmount: "Saque em dinheiro",
      placeWithrawAmount: "Digite o valor da retirada",
      availableLimit: "Crédito disponível",
      fee: "Taxa de manuseio",
      expectedReceipt: "Previsão de chegada",
      withdrawRecord: "Registros de retirada de moedas",
   },
   login: {
      login: "Entrar",
      register: "Registro",
      email: "Caixa de entrada",
      phone: "Telefones celulares",
      forget: "Esqueceu a senha?？",
      noAccount: "Nenhuma conta？",
      haveAccount: "Já possui uma conta？",
      agree1: "Eu entendo.",
      agree2: "Contrato do usuário",
      agree3: "declaração de privacidade",
      resetPassword: "Redefinir senha",
      securityTip: "Para garantir a segurança de seus ativos, você não poderá sacar moedas 24 horas após a alteração da senha",
   },
   form: {
      send: "CAPTCHA",
      labelCaptcha: "CAPTCHA, um tipo de teste de desafio-resposta (computação)",
      errorCaptcha: "Digite o código de verificação",
      labelEmail: "Caixa de entrada",
      errorEmail: "Digite o endereço de e-mail correto",
      placeEmail: "Digite seu endereço de e-mail",
      labelPhone: "Telefones celulares",
      errorPhone: "Digite o número correto do telefone celular",
      placePhone: "Digite seu número de telefone celular",
      labelPassword: "Criptográfico",
      errorPassword: "Digite a senha correta",
      placePassword: "Digite sua senha",
      labelInvite: " Código do convite (opcional)",
      errorInvite: "Por favor, digite o código do convite",
      placeInvite: "Por favor, digite o código do convite",
      placeSelece: "Selecione",
      account_number: "Número da conta",
      label_type: "Selecione o tipo",
      place_type: "Selecione o tipo",
      label_number: "Número da conta",
      place_number: "Número da conta",
      wallet_address: 'Endereço da carteira',
      bank_name: 'Nome do banco',
      bank_address: "Endereço do banco",
      name: "Nome e sobrenome",
      placeName: "Digite um nome",
      payee_address: 'Endereço do beneficiário',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Nome da filial",
      branch_code: "Código de linha",
      bank_code: "Código do banco",
      phone: "Número de telefone celular",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "Digite o conteúdo",
      labelCurrency: "Tipo de moeda",
      placeCurrency: "Selecione a moeda",
      labelNumber: "Quantidades",
      placeNumber: "Digite a quantidade",
      messageNumber: "Digite a quantidade",
      labelPrice: "Preços",
      placePrice: "Digite um preço",
      placeAmount: "Digite o valor",
      labelJoinMoney: "Valor da adesão",
      labelOldPassword: "Senha antiga",
      placeOldPassword: "Digite a senha antiga",
      messageOldPassword: "Digite a senha antiga",
      psdTip: "A senha deve ser uma combinação de letras e dígitos de 6 a 16 bits",
      labelNewPassword: "Nova senha",
      placeNewPassword: "Digite uma nova senha",
      messageNewPassword: "Digite uma nova senha",
      labelCrmPassword: "Confirmar senha",
      placeCrmPassword: "Digite sua senha novamente",
      messageCrmPassword: "Digite sua senha novamente",
      labelCountry: "País/área",
      placeCountry: "Selecione um país",
      messageCountry: "Selecione um país",
      labelId: "Número de identificação",
      messageId: "Digite o número de identificação",
      labelCaptchaEmail: "O código de verificação será enviado para",
      labelTotalAmount: "Total (montante ou valor)",
   },
   table: {
      labelName: "Nome (de uma coisa)",
      labelLastPrice: "Preço mais recente",
      labelRang: "24 horas para cima e para baixo",
      labelVolume: "Volume de negócios 24 horas",
      labelVolumeNum: "Volume 24 horas",
      labelKline: "Gráfico de linha K",
      labelHeight: "24H Alta",
      labelLow: "24H Baixo",
      labelOperate: "Equipamento",
      labelTime: "Horários",
      labelCurrency: "Tipo de moeda",
      labelAmount: "Soma de dinheiro",
      labelState: "Estado das coisas",
      state0: "Feito",
      state1: "Falhar (por exemplo, experimentos)",
      state2: "Cancelado",
      withdrawState0: "Aguardando aprovação",
      withdrawState1: "Feito",
      withdrawState2: "Rejeitado",
      labelDirection: "Orientações",
      labelPrice: "Preços",
      labelLever: "Barra de alavanca",
      labelHangNum: "Número ordinal",
      gmtCreate: "Tempo de criação",
      operate: "Equipamento",
      labelPeriod: "Ciclo de liquidação",
      every: "Cada",
      hour: "Horas",
      day: "Dia",
      labelFundRate: "Taxa de financiamento",
      labelDailyRate: "Taxa de financiamento no mesmo dia",
      labelLevesCurrency: "Moeda de investimento",
      labelReturn: "Taxa de retorno anualizada",
      labelLevesTerm: "Período de investimento",
      labelStartAmount: "Valor inicial",
      viewDetails: "Ver detalhes",
      labelPairs: "Contraparte da transação",
      labelType: "Tipologia",
      labelEntrustNumber: "Número de comissões",
      labelTransNumber: "Número de transações",
      labelTotalAmount: "Valor total da transação",
      labelEntrustTime: "Tempo de comissionamento",
      labelBuyPrice: "Preço da oferta",
      labelBuyNumber: "Quantidade de buy-in",
      labelBuyTime: "Hora da compra",
      labelSellPrice: "Taxa de venda",
      labelProfitLoss: "Crescente e decrescente",
      labelNumber: "Quantidades",
      labelMinerTitle: "Nome financeiro",
   },
   foot: {
      menuTit1: "Sobre nós",
      menuTit2: "Entre em contato conosco",
      menuTit3: "Recurso (como mão de obra ou turismo)",
      menuTit4: "Mão",
      menuTit5: "Negociações",
      menuItem1: "Equipe TokoCrypto",
      menuItem2: "Canal de autenticação",
      menuItem3: "Autorização de cobrança comercial",
      menuItem4: "Associado",
      menuItem5: "Sobre a TokoCrypto",
      menuItem6: "Contrato do usuário",
      menuItem7: "E-mail oficial",
      menuItem8: "Contratos de TokoCrypto",
      menuItem9: "Aplicação de moedas",
      menuItem10: "A situação atual do mercado",
      menuItem11: "TokoCrypto Academy",
      menuItem12: "Tarifas padrão",
      menuItem13: "Taxa de saque e limite de saque",
      menuItem14: "Descrição da moeda",
      menuItem15: "Garantia",
      menuItem16: "Status de preenchimento e retirada",
      menuItem17: "Sugestões e comentários",
      menuItem18: "Central de Ajuda",
      menuItem19: "Envio de ordens de serviço",
      menuItem20: "Documentação da API",
   },
   market: {
      spots: {
         filterTab1: "Autoatendimento",
         filterTab2: "Citações de moedas",
         filterTab3: "Mercado de contratos",
         filterTab4: "Launchpad",
         tabAll: "Completo",
         tab1: "área principal",
         tab2: "Abrir novas trilhas",
         tab3: "área aberta",
         tab4: "Prime",
         tab5: "Moedas",
         tab6: "Contratual",
         tab7: "USDT Forever",
         tab8: "Moeda integral Perpétua",
      }
   },
   trade:{
      chartMsg: "Informações sobre moedas",
      marketPrice:"Valor de mercado",
      limitPrice:"Limite de preço",
      marketPriceTip: "As ordens a mercado são compradas e vendidas usando o melhor preço do mercado no momento.",
      lever: "Barra de alavanca",
      handNum: "Número ordinal",
      margin: "Margem (em negociações de derivativos)",
      assets:"Responsabilidades",
      balance:"Balanços",
      book: "Livro de comissões",
      quantity: "Valor acumulado",
      navState0: "Aguardando para abrir uma posição",
      navState1: "Posição atual",
      navState2: "Comissões históricas",
      navState3: "Comissões atuais",

      fund: {
         tit1: "Mercado de contratos",
         tit2: "Histórico da taxa de financiamento",
         p1: "Um fundo de seguro para evitar que as posições dos investidores sejam automaticamente reduzidas. O fundo é usado para melhorar o preço de mandatos fechados não executados para evitar que sejam assumidos pelo sistema de redução automática de posição.",
         p2: "O aumento no valor do fundo de seguro decorre do fato de que os mandatos de nivelamento forçado são negociados no mercado a um preço melhor do que o preço de insolvência.。",
         p3: "Os saldos atuais e históricos dos fundos de seguro podem ser visualizados abaixo。",
         tab1: "USDT Forever",
         tab2: "Moeda integral Perpétua",
         contract: "Contratual",
      },
   },
   earn: {
      tit: "Compromisso de um clique, aumentando a renda todos os dias",
      tab1: "Financiamento a prazo fixo",
      orderTit: "Ordens financeiras",
      labelTotalApr: "Taxa de retorno anualizada",
      labelTimeLimit: "Ciclo do projeto",
      labelMoney: "Valor da adesão",
      labelType: "Método de retorno do investimento",
      labelType1: "Serviço da dívida no vencimento",
      labelNowBalance: "Saldo atual",
   },
   invite: {
      tit: "Convidar amigos",
      p: "Leia este código QR para convidar amigos",
      iviteLink: "Link do convite",
      registerCode: "Código de registro",
   },
   noPermissions: {
      tip: "Desculpe, sua área é temporariamente a semana de defesa da lei Jiao nesta estação de plataforma, a lei negra para fornecer serviços para mover!",
   },
   order: {
      tit: "Registro de pedidos",
      tab1: "Ordens de opções",
      tab2: "Pedidos de moedas",
      tab3: "Ordem de contrato",
      tab4: "Ordens financeiras",
      filterType0: "Todos os tipos",
      filterType1: "Feito",
      filterType2: "Cancelado",
      filterType3: "Posição atual",
      filterDirection0: "Gama completa de direções",
      filterDirection1: "Adesão",
      filterDirection2: "Alcance (um preço em um leilão)",
   },
   personal: {
      tit1: "Nível de segurança",
      tit2: "Senha de login",
      tit3: "Senha de negociação",
      tit4: "Configurações de segurança",
      strong: "Vigoroso",
      setting: "Configurar",
      settings: "Configurar",
      change: "Variação",
      idTip1: "Autenticar",
      idTip2: "Autenticação antes de prosseguir com o restante da operação",
      withdrawTip1: "Endereço de retirada",
      withdrawTip2: "Favor vincular o endereço de retirada",
      loginPsdTit: "Definição da senha de login",
      withdrawPsdTit: "Definição de uma senha para saques",
      idTit: "Autenticação por nome real",
      phoneTit: "Vinculação de telefones celulares",
      phoneTip: "Não há cancelamento por 24 horas após a mudança de telefone",
      walletTit: "Carteira de encadernação",
      accountNumber: "Número da conta",
      addAddress: "Adicionar endereço",

   },
   contest: {
      hot: "Eventos quentes",
   },
   city: {
		albania: "Albânia",
		algeria: "Argélia",
		argentina: "Argentina",
		armenia: "Arménia",
		australia: "Continente",
		pakistan: "Paquistão",
		austria: "Continente",
		bahrain: "Bahrein",
		belgium: "Bélgica",
		bosnia_and_Herzegovina: "Bósnia e Herzegovina",
		brazil: "Brasil",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		cambodia: "Camboja",
		canada: "Canadá",
		cameroon: "Camarões",
		chile: "Chile",
		colombia: "Colômbia",
		costa_Rica: "Costa Rica",
		croatia: "Croácia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estónia",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		france: "França",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		greece: "Grécia",
		guyana: "Guiana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungria",
		iceland: "Islândia",
		ireland: "Irlanda",
		italy: "Itália",
		india: "Índia",
		indonesia: "Indonésia",
		israel: "Israel",
		iran: "Irão",
		iraq: "Iraque",
		japan: "Japão",
		kazakstan: "Cazaquistão",
		kenya: "Quénia",
		korea: "Coreia",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguizistão",
		laos: "Laos",
		latvia: "Letónia",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao_China: "Macau, China",
		macedonia: "Macedónia",
		malaysia: "Malásia",
		malta: "Malta",
		mexico: "México",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		morocco: "Marrocos",
		myanmar: "MianmarName",
		netherlands: "Países Baixos",
		new_Zealand: "Nova Zelândia",
		nepal: "Nepal",
		nigeria: "Nigéria",
		norway: "Noruega",
		oman: "Omã",
		palestine: "Palestina",
		panama: "Panamá",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		poland: "poland",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roménia",
		russia: "Rússia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arábia Saudita",
		serbia: "Sérvia",
		singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovénia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka: "Sri Lanka",
		sweden: "Suécia",
		switzerland: "Suíça",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		turkey: "Türkiye",
		turkmenistan: "Turquemenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "EAU",
		united_Kingdom: "Grã-Bretanha",
		united_States: "Estados Unidos",
		uzbekistan: "Usbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietname",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belizefrance. kgm",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Caimão",
		central_African_Republic: "República Centro-Africana",
		chad: "ChadeName",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "JibutiName",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Gronelândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "SenegalName",
		sierra_Leone: "sierra leone",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens, Estados Unidos",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "zimbabwe",
		china: "China",
	}
};






