export default {
   params: {
      transType0: "Komoditas aktual",
      transType1: "Kontrak",
      directionType0: "Beli ",
      directionType1: "Jual",
      lockStateType0: "Menuju",
      lockStateType1: "Ditutup",
      lockStateType2: "Dibatalkan",
   },
   common: {
      withdraw1: "Akun penarikan",
      withdraw2: "Pilih Jenis",
      remark: "Keterangan",
      verify:"Akreditasi",
      verifySuccess:"Bersertifikat",
      onlineService: "layanan klien",
      tip1: "Harap masukkan jumlah yang benar",
      tip2: "Saldo rekening tidak mencukupi",
      confirm: "Konfirmasi",
      cancel: "Batal",
      tip: "Tip",
      register: "Daftar",
      viewMore: "Lihat Lebih Lanjut",
      trade: "Perdagangan",
      empty: "Tidak ada konten",
      searchPairs: "Cari Pasangan",
      and: "Dan",
      next: "Langkah selanjutnya",
      success: "Berhasil",
      more: "Lebih lanjut",
      main: "Gambar utama",
      sub: "Gambar",
      copySuccess: "Salin Berhasil！",
      copyError: "Gagal Menyalin！",
      view: "Melihat",
      all: "Penuh",
      goSet: "Pengaturan",
      available: "Kegunaan",
      confirmCancel: "Apakah Anda yakin ingin membatalkan？",
      copy: "Clone",
      submit: "Ajukan",
      send: "Terkirim",
      keep: "Konfirmasi",
      revenue: "Keuntungan",
   },
   mine: {
      lastTime: "Login terakhir",
   },
   head: {
      mineInvite: "Undang Teman",
      mineAddress: "Alamat penarikan dana",
      mine: "Akun",
      orderCoin: "Pesanan Koin",
      orderContract: "Pesanan Kontrak",
      orderSecond: "Pesanan Spot",
      order: "Riwayat",
      assetsWithdraw: "Menarik uang",
      assetsRecharge: "Menyetor Uang",
      assetsTotal: "Total aset",
      assets: "Aset",
      logout: "Keluar",
      menuTit1: "Pasar",
      menuTit2: "Perdagangan",
      menuTit3: "Deposito",
      menuTit4: "Mata Uang Baru",
      menuTit5: "Berita",
      menuTit6: "Acara",
      menuTit7: "Blogger",
      menuTit8: "Temukan",
      menuItem0: "Perdagangan Spot",
      menuItem1: "Perdagangan Koin",
      menuItem2: "Kontrak Perdagangan",
      menuItem3: "Landasan peluncuran",
      menuItem4: "Pilih di Koin",
      menuItem5: "Siaran langsung",
      menuItem6: "Acara Offline",
      login: "Masuk",
      register: "Daftar",
      download: "Unduh",
      noPermissions: "Alamat IP Anda menunjukkan bahwa Anda berada di negara di mana layanan TokoCrypto dibatasi.",
   },
   home: {
      h1: "Pilihan Pertama Untuk Peluncuran Perdana",
      p1: "Perdagangkan lebih dari 50 mata uang kripto di TokoCrypto",
      tradeTab1: "Perdagangan Koin",
      tradeTab2: "Transaksi Kontraktual",
      tradeTag1: "Naik dan turunnya saham dalam satu mata uang",
      tradeTag2: "Koin Panas",
      tradeTag3: "Perdana",
      serviceTit: "Berbagai macam layanan yang dapat dipilih",
      serviceTit1: "Perdagangan Spot",
      servicedesc1: "Membeli, menjual, atau memperdagangkan mata uang kripto dengan harga pasar yang berlaku.",
      serviceTit2: "Kontrak Permanen",
      servicedesc2: "Memperdagangkan kontrak dengan mengambil posisi long atau short",
      serviceTit3: "Keuangan",
      servicedesc3: "Janji yang meningkatkan pendapatan dari hari ke hari.",
      serviceTit4: "Perdana",
      servicedesc4: "Mengesahkan token baru yang berkualitas",
      liveTit: "Streaming Langsung TokoCrypto",
      whyTit: "Streaming Langsung TokoCrypto",
      whyTit1: "Aman dan dapat dipercaya",
      whySubtit1: "Kliring internal waktu nyata, audit, dan manajemen risiko Pemisahan dompet dingin dan dompet multi-tanda tangan untuk melindungi dana, Audit organisasi keamanan pihak ketiga yang profesional, Audit organisasi keamanan pihak ketiga yang profesional",
      whyLink: "Lihat Semua Mekanisme Keamanan",
      whyTit2: "Mesin agregasi transaksi yang kuat",
      whySubtit2: "Mesin peringkasan latensi sangat rendah, throughput besar, tidak ada jitter di puncak ekstrem, tidak ada waktu henti di puncak",
      whyTit3: "Platform Tepercaya",
      whySubtit3: "Sebagai salah satu dari 50 platform perdagangan teratas di dunia, TokoCrypto secara ketat mengikuti aturan industri dan tidak berpartisipasi dalam operasi pasar.",
      whyTit4: "Sistem perdagangan cepat",
      whySubtit4: "TokoCrypto memberi Anda antarmuka perdagangan yang sederhana dan jelas serta proses operasi yang mudah, membuatnya lebih nyaman bagi Anda untuk berdagang aset digital.",
      guideTit: "Panduan Pemula",
      guideh1: "Buat Akun TokoCrypto",
      guidep1: "Daftar sekarang dan mulai perjalanan trading Anda!",
      guideBtn1: "Daftar",
      guideh2: "Depositor",
      guidep2: "Isi ulang yang cepat dan kredit yang mudah!",
      guideBtn2: "Pengisian koin",
      guideh3: "Mulai berdagang",
      guidep3: "Mulai berdagang dan nikmati kemungkinan tak terbatas!",
      guideBtn3: "Perdagangan Koin",
      readyTop1: "Siap untuk memulai？",
      readyMid: "TokoCrypto memiliki teknologi canggih untuk memberi Anda pengalaman membeli, menjual, dan berdagang yang cepat. Berdagang dengan aman dengan platform sederhana kami yang dibuat hanya untuk Anda.",
      readyTit: "Memindai untuk mengunduh aplikasi",
      readySubtit: "Didukung Android dan iOS",
      readyDownload: "Unduh di",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "PERTANYAAN YANG SERING DIAJUKAN",
      faqTit1: "Pengalaman Pendanaan",
      faqCont1: "Futures Credit adalah bonus pinjaman uang digital untuk pengguna TokoCrypto untuk membantu mereka memahami perdagangan kontrak abadi TokoCrypto. Dibandingkan dengan perdagangan berjangka tradisional TokoCrypto, dikombinasikan dengan jumlah pokok aset kripto yang disediakan oleh pengguna, Kredit Berjangka dapat digunakan sebagai margin pembukaan yang relatif besar untuk perdagangan Kredit Berjangka.",
      faqTit2: "Apa itu CoinBox?",
      faqCont2: "Futures CoinBox adalah fitur produk baru dari TokoCrypto yang memungkinkan Anda untuk mengirim CoinBox mata uang kripto kepada teman Anda, Anda dapat membuat CoinBox mata uang kripto dan membaginya dengan teman Anda. Ketika teman Anda memasukkan kata sandi CoinBox melalui aplikasi TokoCrypto untuk menerima hadiah, mereka akan menerima sejumlah cryptocurrency secara acak dari CoinBox.",
      faqTit3: "Panduan Pemula untuk Menghasilkan Uang",
      faqCont3: "Produk manajemen kekayaan cryptocurrency terbaru TokoCrypto dibuat oleh tim kuantitatif TokoCrypto, yang terdiri dari manajer dana investasi kuantitatif yang berpengalaman, elit keuangan dari industri berjangka dan sekuritas, dan ahli teknologi BAT. Tim ini didirikan di Singapura pada tahun 2021 dan berfokus pada manajemen kuantitatif aset digital dengan solusi pemodelan kuantitatif khusus dan kemampuan manajemen Kredit dapat digunakan sebagai margin pembukaan yang relatif besar dalam perdagangan Kredit Berjangka.",
      faqTit4: "Bergabunglah dengan kami secara langsung di TokoCrypto",
      faqCont4: "Futures Live adalah produk streaming langsung yang menampilkan proyek-proyek blockchain memori teratas dan para pakar industri yang memberikan pembicaraan dan presentasi terbaru dan terhangat. Bergabunglah dengan kami untuk menjelajahi konten yang lebih menarik Kredit dapat digunakan sebagai margin pembukaan yang relatif besar dalam perdagangan Kredit Berjangka.",
      faqTit5: "Cara membeli mata uang kripto di TokoCrypto",
      faqCont5: "Futures TokoCrypto menawarkan beberapa penyedia layanan pembelian mata uang kripto pihak ketiga kepada pengguna di seluruh dunia. Lihat artikel kami tentang layanan pembelian mata uang kripto Kredit dapat digunakan sebagai margin pembukaan yang relatif besar untuk perdagangan Kredit Berjangka.",
      partnerTit: "Mitra kerja sama",
   },
   assets: {
      tit: "Total aset",
      recharge: "Pengisian koin",
      withdraw: "Menarik uang",
      transfer: "Transfer",
      tab1: "Total aset",
      tab2: "Akun Koin",
      tab3: "Akun kontrak",
      tab4: "akun kuantitatif kontraktual (CQA)",
      tab5: "Akun Keuangan",
      assetsTotal: "Aset Keseluruhan",
      assetsReal: "Aset Tersedia",
      assetsFrozen: "Beku",
      proportion: "Persentase aset",
      tip1: "Penarikan Minimum",
      tip2: "Penarikan di bawah jumlah minimum tidak akan dikreditkan dan tidak dapat dikembalikan",
      tip3: "Penarikan Minimum",
      linkType: "Jenis rantai",
      rechargeAddress: "Alamat pengisian",
      contractAddress: "Alamat kontrak",
      rechargeRecord: "Catatan Pengisian Koin",
      tip4: "Anda belum menyelesaikan pengaturan kata sandi, tidak mendukung pengisian koin untuk saat ini.",
      withrawAddress: "Alamat penarikan dana",
      placeWithrawAddress: "Silakan masukkan alamat penarikan",
      withrawAmount: "Jumlah penarikan",
      placeWithrawAmount: "Silakan masukkan jumlah penarikan",
      availableLimit: "Kredit yang tersedia",
      fee: "Biaya layanan",
      expectedReceipt: "Kedatangan yang diproyeksikan",
      withdrawRecord: "Catatan penarikan koin",
   },
   login: {
      login: "Masuk",
      register: "Daftar",
      email: "Email",
      phone: "Telepon",
      forget: "Lupa kata sandi？",
      noAccount: "Tidak ada akun？",
      haveAccount: "Sudah memiliki akun?",
      agree1: "Aku mengerti.",
      agree2: "Perjanjian pengguna",
      agree3: "Perjanjian privasi",
      resetPassword: "Atur Ulang Kata Sandi",
      securityTip: "Untuk memastikan keamanan aset Anda, Anda tidak dapat menarik koin 24 jam setelah perubahan kata sandi",
   },
   form: {
      send: "Kirim Kode",
      labelCaptcha: "Kode Verifikasi",
      errorCaptcha: "Silakan masukkan kode verifikasi",
      labelEmail: "Email",
      errorEmail: "Silakan masukkan Email",
      placeEmail: "Silakan masukkan Email",
      labelPhone: "Telepon",
      errorPhone: "Masukkan nomor ponsel yang benar!",
      placePhone: "Silakan masukkan nomor ponsel Anda",
      labelPassword: "Kata sandi",
      errorPassword: "Masukkan kata sandi login Anda",
      placePassword: "Masukkan kata sandi login Anda",
      labelInvite: " Kode Undangan (opsional)",
      errorInvite: "Silakan masukkan kode undangan",
      placeInvite: "Silakan masukkan kode undangan",
      placeSelece: "Silakan pilih",
      account_number: "Nomor Rekening",
      label_type: "Pilih Jenis",
      place_type: "Silakan pilih jenis",
      label_number: "Nomor Rekening",
      place_number: "Nomor Rekening",
      wallet_address: 'Alamat Dompet',
      bank_name: 'Nama bank',
      bank_address: "Alamat Bank",
      name: "Nama",
      placeName: "Silakan masukkan nama",
      payee_address: "Alamat penerima pembayaran",
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Nama Cabang",
      branch_code: "Kode baris",
      bank_code: "Kode Bank",
      phone: "Nomor telepon seluler",
      iban: "IBAN",
      sort_code: "Mengurutkan Kode",
      placeInner: "Silakan masukkan konten",
      labelCurrency: "Jenis mata uang",
      placeCurrency: "Silakan pilih mata uang",
      labelNumber: "Kuantitas",
      placeNumber: "Silakan masukkan jumlahnya",
      messageNumber: "Silakan masukkan jumlahnya",
      labelPrice: "harga",
      placePrice: "Silakan masukkan harga",
      placeAmount: "Silakan masukkan jumlahnya",
      labelJoinMoney: "Jumlah Aset",
      labelOldPassword: "Kata sandi lama",
      placeOldPassword: "Masukkan kata sandi lama",
      messageOldPassword: "Masukkan kata sandi lama",
      psdTip: "Kata sandi harus berupa kombinasi 6 ~ 16 bit huruf + angka",
      labelNewPassword: "Kata sandi baru",
      placeNewPassword: "Masukkan kata sandi baru",
      messageNewPassword: "Masukkan kata sandi baru",
      labelCrmPassword: "Konfirmasi Kata Sandi",
      placeCrmPassword: "Harap konfirmasikan kata sandi login Anda",
      messageCrmPassword: "Harap konfirmasikan kata sandi login Anda",
      labelCountry: "Negara/Wilayah",
      placeCountry: "Silakan pilih negara",
      messageCountry: "Silakan pilih negara",
      labelId: "Nomor ID",
      messageId: "Silakan masukkan nomor ID",
      labelCaptchaEmail: "Kode verifikasi akan dikirimkan ke",
      labelTotalAmount: "Total",
   },
   table: {
      labelName: "Nama Koin",
      labelLastPrice: "Harga terbaru",
      labelRang: "Perubahan 24 Jam",
      labelVolume: "Perputaran 24 jam",
      labelVolumeNum: "Volume 24 jam",
      labelKline: "Bagan K-line",
      labelHeight: "Tertinggi",
      labelLow: "Terendah",
      labelOperate: "Memanipulasi",
      labelTime: "Waktu",
      labelCurrency: "Jenis mata uang",
      labelAmount: "Modal Digunakan",
      labelState: "Keadaan",
      state0: "Selesai.",
      state1: "Kegagalan",
      state2: "Dibatalkan",
      withdrawState0: "Menunggu persetujuan",
      withdrawState1: "Selesai.",
      withdrawState2: "Ditolak",
      labelDirection: "Arah",
      labelPrice: "Harga",
      labelLever: "Bilah cungkil",
      labelHangNum: "segenggam (dari)",
      gmtCreate: "Waktu pembuatan",
      operate: "Memanipulasi",
      labelPeriod: "Siklus penyelesaian",
      every: "Masing-masing",
      hour: "Setiap jam",
      day: "Hari",
      labelFundRate: "Tingkat pendanaan",
      labelDailyRate: "Tingkat pendanaan di hari yang sama",
      labelLevesCurrency: "Pilihan Investasi",
      labelReturn: "Keuntungan Pertahun",
      labelLevesTerm: "Periode Investasi",
      labelStartAmount: "Modal Awal",
      viewDetails: "Lihat Detail",
      labelPairs: "Pasangan perdagangan",
      labelType: "Tipologi",
      labelEntrustNumber: "Jumlah komisi",
      labelTransNumber: "Jumlah transaksi",
      labelTotalAmount: "Jumlah total yang diperdagangkan",
      labelEntrustTime: "Waktu komisioning",
      labelBuyPrice: "Harga pembelian",
      labelBuyNumber: "Jumlah pembelian",
      labelBuyTime: "Waktu pembelian",
      labelSellPrice: "Harga Pembukaan",
      labelProfitLoss: "Keuntungan/Kerugian",
      labelNumber: "Kuantitas",
      labelMinerTitle: "Nama Keuangan",
   },
   foot: {
      menuTit1: "Tentang Kami",
      menuTit2: "Hubungi Kami",
      menuTit3: "Pasaran",
      menuTit4: "Bantuan",
      menuTit5: "Perdagangan",
      menuItem1: "Tim TokoCrypto",
      menuItem2: "Saluran otentikasi",
      menuItem3: "Otorisasi Penagihan Bisnis",
      menuItem4: "Rekan",
      menuItem5: "Tentang TokoCrypto",
      menuItem6: "Perjanjian pengguna",
      menuItem7: "Email Resmi",
      menuItem8: "Kontrak TokoCrypto",
      menuItem9: "Aplikasi koin",
      menuItem10: "Harga pasar",
      menuItem11: "Akademi TokoCrypto",
      menuItem12: "Tarif standar",
      menuItem13: "Biaya Penarikan & Batas Penarikan",
      menuItem14: "Deskripsi Mata Uang",
      menuItem15: "Jaminan",
      menuItem16: "Status Pengisian & Penarikan Dana",
      menuItem17: "Saran dan umpan balik",
      menuItem18: "Pusat Bantuan",
      menuItem19: "Layanan Pelanggan",
      menuItem20: "Dokumentasi API",
   },
   market: {
      spots: {
         filterTab1: "Layanan mandiri",
         filterTab2: "Kutipan Koin",
         filterTab3: "Pasar kontrak",
         filterTab4: "Landasan peluncuran",
         tabAll: "Penuh",
         tab1: "Area utama",
         tab2: "Merintis jalan baru",
         tab3: "Area terbuka",
         tab4: "Perdana",
         tab5: "Koin",
         tab6: "Kontraktor",
         tab7: "USDT Selamanya",
         tab8: "Mata Uang Penuh Perpetual",
      }
   },
   trade:{
      chartMsg: "Informasi Mata Uang",
      marketPrice:"Nilai pasar",
      limitPrice:"Batasi harga",
      marketPriceTip: "Market order dibeli dan dijual menggunakan harga pasar terbaik saat ini.",
      lever: "Bilah cungkil",
      handNum: "Segenggam (dari)",
      margin: "Setoran tunai",
      assets:"Modal",
      balance:"Saldo",
      book: "Catatan Harga",
      quantity: "Volume Transaksi",
      navState0: "Menunggu untuk membuka posisi",
      navState1: "Posisi saat ini",
      navState2: "Riwayat Pesanan",
      navState3: "Pesanan saat ini",

      fund: {
         tit1: "Pasar kontrak",
         tit2: "Riwayat suku bunga pendanaan",
         p1: "Dana asuransi untuk mencegah posisi investor dikurangi secara otomatis. Dana ini digunakan untuk meningkatkan harga mandat tertutup yang belum dieksekusi agar tidak diambil alih oleh sistem pengurangan posisi otomatis.",
         p2: "Peningkatan jumlah dana asuransi berasal dari mandat flat yang kuat yang diperdagangkan di pasar dengan harga yang lebih baik dari harga kebangkrutan.",
         p3: "Saldo dana asuransi saat ini dan historis dapat dilihat di bawah ini.",
         tab1: "USDT Selamanya",
         tab2: "Mata Uang Penuh Perpetual",
         contract: "Kontraktor",
      },
   },
   earn: {
      tit: "Cukup Sekali Klik Dan Tingkatkan Penghasilan Harian Anda",
      tab1: "Investasi Jangka Pendek",
      orderTit: "Perintah Keuangan",
      labelTotalApr: "Keuntungan Berdasarkan Persen",
      labelTimeLimit: "Periode Investasi",
      labelMoney: "Modal Berlangganan",
      labelType: "Berlangganan Kembali",
      labelType1: "Berlangganan Kembali Setelah Jatuh Tempo",
      labelNowBalance: "Saldo saat ini",
   },
   invite: {
      tit: "Undang Teman",
      p: "Pindai kode QR ini untuk mengundang teman",
      iviteLink: "Tautan Undangan",
      registerCode: "Kode pendaftaran",
   },
   noPermissions: {
      tip: "Maaf, Anda berada di area minggu pertahanan hukum Jiao sementara, stasiun program ini, hukum hitam untuk menyediakan layanan untuk pindah",
   },
   order: {
      tit: "Riwayat Pesanan",
      tab1: "Pesanan Spot",
      tab2: "Pesanan Koin",
      tab3: "Pesanan kontrak",
      tab4: "Perintah Keuangan",
      filterType0: "Semua jenis",
      filterType1: "Selesai.",
      filterType2: "Dibatalkan",
      filterType3: "Posisi saat ini",
      filterDirection0: "Arah Penuh",
      filterDirection1: "Beli",
      filterDirection2: "Jual",
   },
   personal: {
      tit1: "Tingkat keamanan",
      tit2: "Kata sandi masuk",
      tit3: "Kata Sandi Perdagangan",
      tit4: "Pengaturan Keamanan",
      strong: "Lemah",
      setting: "Perubahan",
      settings: "Pengaturan",
      change: "Variasi",
      idTip1: "Mengotentikasi",
      idTip2: "Autentikasi diri Anda sebelum melanjutkan operasi selanjutnya",
      withdrawTip1: "Alamat penarikan dana",
      withdrawTip2: "Harap ikat alamat penarikan dana",
      loginPsdTit: "Mengatur kata sandi masuk",
      withdrawPsdTit: "Mengatur kata sandi untuk penarikan dana",
      idTit: "Verifikasi Identitas",
      phoneTit: "Penjilidan Telepon Seluler",
      phoneTip: "Pembatalan dilarang selama 24 jam setelah penggantian ponsel",
      walletTit: "Dompet Pengikat",
      accountNumber: "Nomor Rekening",
      addAddress: "Tambahkan Alamat",

   },
   contest: {
      hot: "Acara Panas",
   },
   city: {
      albania: "Albania",
      algeria: "Aljazair",
      argentina: "Argentina",
      armenia: "Armenia",
      australia: "Australia",
      pakistan: "Pakistan",
      austria: "Austria",
      bahrain: "Bahrain",
      belgium: "Belgia",
      bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
      brazil: "Brasil",
      brunei: "Brunei",
      bulgaria: "Bulgaria",
      cambodia: "Kamboja",
      canada: "Kanada",
      cameroon: "Kamerun",
      chile: "Chili",
      colombia: "Kolombia",
      costa_Rica: "Kosta Rika",
      croatia: "Kroasia",
      cyprus: "Siprus",
      czech_Republic: "Republik Ceko",
      denmark: "Denmark",
      dominican_Republic: "Republik Dominika",
      egypt: "Mesir",
      estonia: "Estonia",
      ethiopia: "Ethiopia",
      finland: "Finlandia",
      france: "Prancis",
      georgia: "Georgia",
      germany: "Jerman",
      ghana: "Ghana",
      greece: "Yunani",
      guyana: "Guyana",
      honduras: "Honduras",
      hong_Kong_China: "Hong Kong, China",
      hungary: "Hongaria",
      iceland: "Islandia",
      ireland: "Irlandia",
      italy: "Italia",
      india: "India",
      indonesia: "Indonesia",
      israel: "Israel",
      iran: "Iran",
      iraq: "Irak",
      japan: "Jepang",
      kazakstan: "Kazakhstan",
      kenya: "Kenya",
      korea: "Korea",
      kuwait: "Kuwait",
      kyrgyzstan: "Kyrgyzstan",
      laos: "Laos",
      latvia: "Latvia",
      lithuania: "Lithuania",
      luxembourg: "Luksemburg",
      macao_China: "Macao_China",
      macedonia: "Makedonia",
      malaysia: "Malaysia",
      malta: "Malta",
      mexico: "Meksiko",
      moldova: "Moldova",
      monaco: "Monaco",
      mongolia: "Mongolia",
      montenegro: "Montenegro",
      morocco: "Maroko",
      myanmar: "Myanmar",
      netherlands: "Belanda",
      new_Zealand: " New_Zealand",
      nepal: "Nepal",
      nigeria: "Nigeria",
      norway: "Norse",
      oman: "Oman",
      palestine: "Palestina",
      panama: "Panama",
      paraguay: "Paraguay",
      peru: "Peru",
      philippines: "Filipina",
      poland: "Polandia",
      portugal: "Portugal",
      puerto_Rico: "Puerto Rico",
      qatar: "Qatar",
      romania: "Rumania",
      russia: "Rusia",
      republic_of_Trinidad_and_Tobago: "Republik Trinidad dan Tobago",
      rwanda: "Rwanda",
      saudi_Arabia: "Arab Saudi",
      serbia: "Serbia",
      singapore: "Singapura",
      slovakia: "Slovakia",
      slovenia: "Slovenia",
      south_Africa: "Afrika Selatan",
      spain: "Spanyol",
      sri_Lanka: "Sri Lanka",
      sweden: "Swedia",
      switzerland: "Swiss",
      taiwan_China: "Taiwan_China",
      tajikistan: "Tajikistan",
      tanzania: "Tanzania",
      thailand: "Thailand",
      turkey: "Turki",
      turkmenistan: "Turkmenistan",
      ukraine: "Ukraina",
      united_Arab_Emirates: "Uni Emirat Arab",
      united_Kingdom: "Inggris Raya",
      united_States: " United_States",
      uzbekistan: "Uzbekistan",
      venezuela: "Venezuela",
      vietnam: "Vietnam",
      afghanistan: "Afghanistan",
      angola: "Angola",
      azerbaijan: "Azerbaijan",
      bangladesh: "Bangladesh",
      belarus: "Belarus",
      belize: "Belize",
      benin: "Benin",
      bhutan: "Bhutan",
      bolivia: "Bolivia",
      botswana: "Botswana",
      british_Virgin_Islands: "Kepulauan Virgin Britania Raya",
      burkina_Faso: "Burkina Faso",
      burundi: "Burundi",
      cape_Verde: "Tanjung Verde",
      cayman_Islands: "Kepulauan Cayman",
      central_African_Republic: "Republik Afrika Tengah",
      chad: "Chad",
      comoros: "Komoro",
      the_Republic_of_the_Congo: " Republik_Kongo",
      democratic_Republic_of_the_Congo: "Republik Demokratik Kongo",
      djibouti: "Djibouti",
      ecuador: "Ekuador",
      el_Salvador: "El Salvador",
      equatorial_Guinea: "Guinea Khatulistiwa",
      eritrea: "Eritrea",
      fiji: "Fiji",
      gabon: "Gabon",
      gambia: "Gambia",
      greenland: "Greenland",
      guatemala: "Guatemala",
      guinea: "Guinea",
      haiti: "Haiti",
      isle_of_Man: " Isle_of_Man",
      cote_d_Ivoire: "Cote_d_Ivoir",
      jamaica: "Jamaika",
      jordan: "Jordan",
      lebanon: "Lebanon",
      lesotho: "Lesotho",
      liberia: "Liberia",
      libya: "Libya",
      madagascar: "Madagaskar",
      malawi: "Malawi",
      maldives: "Maladewa",
      mali: "Mali",
      mauritania: "Mauritania",
      mauritius: "Mauritius",
      mozambique: "Mozambik",
      namibia: "Namibia",
      nicaragua: "Nikaragua",
      republic_of_Niger: "Republik_Nigeria",
      north_Korea: " Korea Utara",
      reunion: "Reuni",
      san_Marino: "SAN Marino",
      senegal: "Senegal",
      sierra_Leone: "Sierra Leone",
      somalia: "Somalia",
      sudan: "Sudan",
      suriname: "Suriname",
      eswatini: "Eswatini",
      syria: "Suriah",
      togo: "Togo",
      tonga: "Tonga",
      tunisia: "Tunisia",
      united_States_Virgin_Islands: "Kepulauan Virgin Amerika Serikat",
      uganda: "Uganda",
      uruguay: "Uruguay",
      vatican: "Vatikan",
      yemen: "Yaman",
      yugoslavia: "Yugoslavia",
      zambia: "Zambia",
      zimbabwe: "Zimbabwe",
      china: "China",
   }
};






